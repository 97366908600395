import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../../contexts/DataContext";
import Panel from "../UI/Panel/Panel";
import PanelContent from "../UI/Panel/PanelContent";
import PanelTitle from "../UI/Panel/PanelTitle";
import styled from "styled-components";
import FormationEditor from "../Modals/FormationEditor";
import { Button } from "../../Theme/Hyper";

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
  > tbody > tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }

  th {
    padding: 10px;
    border-top: 1px solid #dee2e6;
  }
  td {
    padding-left: 10px;
    padding-right: 10px;
    border-top: 1px solid #dee2e6;
    width: ${(props) => props.width};
    padding: 8px;
    padding-left: 10px;
    padding-right: 10px;
    border-top: 1px solid #dee2e6;
    white-space: nowrap;
    overflow: hidden;
    font-size: 0.8em;
  }
  > tbody > tr:hover {
    background-color: #14ec8e;
    color: white;
    cursor: pointer;
  }
`;

const TH = styled.th`
  border-top: 0;
  border-bottom: 0;
  font-weight: 800;
  font-size: 0.8em;
  text-transform: uppercase;
  color: #343a40;
  text-align: left;
`;
export default function FormationList() {
  const { getFormation } = useContext(DataContext);
  const [formations, setFormations] = useState();
  const [selectedFormation, setSelectedFormation] = useState();

  useEffect(() => {
    getData();
  }, []);

  function getData() {
    getFormation({}).then((data) => {
      setFormations(data);
    });
  }
  return (
    <>
      <Button
        style={{ width: "200px", margin: "1em" }}
        green
        onClick={() => {
          setSelectedFormation({
            positions: [
              {
                x: 0,
                y: 87,
              },
              {
                x: -36,
                y: 47,
              },
              {
                x: -12,
                y: 47,
              },
              {
                x: 12,
                y: 47,
              },
              {
                x: 36,
                y: 47,
              },
              {
                x: -28,
                y: 2,
              },
              {
                x: 0,
                y: 2,
              },
              {
                x: 28,
                y: 2,
              },
              {
                x: -35,
                y: -47,
              },
              {
                x: 0,
                y: -47,
              },
              {
                x: 35,
                y: -47,
              },
            ],
          });
        }}
      >
        Create New
      </Button>
      <Panel style={{ marginTop: "1em" }}>
        <PanelTitle title={"Formations List"} />
        <PanelContent>
          <Table>
            <thead>
              <tr>
                <TH>ID</TH>
                <TH>Name</TH>
                <TH>Rows</TH>
              </tr>
            </thead>
            <tbody>
              {formations &&
                formations.map((formation) => {
                  return (
                    <tr
                      onClick={() => {
                        setSelectedFormation(formation);
                      }}
                    >
                      <td>{formation._id}</td>
                      <td>{formation.name}</td>
                      <td>{formation.rows}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </PanelContent>
      </Panel>
      {selectedFormation && (
        <FormationEditor
          formation={selectedFormation}
          selectFormation={(s) => {
            setSelectedFormation(s);
          }}
          onCancel={() => {
            setSelectedFormation();
          }}
          onSave={() => {
            setSelectedFormation();
            getData();
          }}
        />
      )}
    </>
  );
}
