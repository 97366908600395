import React, { useState, useContext, useCallback, useEffect } from "react";
import styled from "styled-components";
import { BsChevronRight, BsChevronDown } from "react-icons/bs";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Button } from "../../Theme/Hyper";
import FormationImg from "../../Images/Formation2.png";
import { DataContext } from "../../contexts/DataContext";
import { UIContext } from "../../contexts/UIContext";
import Panel from "../UI/Panel/Panel";
import PanelTitle from "../UI/Panel/PanelTitle";
import PanelContent from "../UI/Panel/PanelContent";
import Grid from "styled-components-grid";
import Input from "../UI/Form/Input";
import Modal from "../UI/Modal/Modal";
import SavePanel from "../UI/Form/SavePanel";
import ConfirmModal from "../Modals/ConfirmModal";
import SelectUI from "../UI/Form/SelectUI";
const Container = styled.div`
  display: flex;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
`;
const Main = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  background-color: #fff;
  margin-top: 100px;
`;
const Row = styled.div`
  position: absolute;
  display: flex;
  font-size: 1em;
  font-weight: 100;
  align-items: center;
  box-sizing: border-box;
  transform: translate(-50%, -50%);
  flex-direction: column;
  justify-content: center;
  cursor: ${(props) => (props.clickable ? "pointer" : "")};
`;

const FormationImgDiv = styled.img`
  height: auto;
  width: ${930 / 1.5 + "px"};
  /* position: absolute; */
`;

const PitchDummy = styled.div`
  display: flex;
  height: 630px;
`;

const FormationRows = styled.div`
  position: relative;
  top: -123px;
`;
const Square = styled.div`
  background-color: ${(props) => (props.selected ? "#db0a41" : "#293b52")};
  color: #fff;
  min-width: 40px;
  min-height: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-weight: bold;
  flex-direction: column;
`;

const FormationNumber = styled.div`
  font-size: 10px;
`;

const FormationList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const FormationName = styled.div`
  display: flex;
  background-color: ${(props) => (props.selected ? "#dee2e6" : "")};
  color: ${(props) => (props.selected ? "#db0a41" : "")};
  border-radius: 0.25rem;
  width: 100%;
  padding: 0.375rem 1rem;
  cursor: pointer;
  padding-left: 0.5em;
  :hover {
    background-color: #0000000d;
  }
`;

const Buttons = styled.div`
  display: flex;
`;

const LastName = styled.div`
  display: flex;
  white-space: nowrap;
  background-color: #e9ecef;
  border-radius: 2px;
  padding-left: 3px;
  padding-right: 3px;
  font-weight: 300;
  font-size: 13px;
`;
const PositionLabel = styled.div`
  display: flex;
  white-space: nowrap;
  /* background-color: #e9ecef; */
  border-radius: 2px;
  padding-left: 3px;
  padding-right: 3px;
  font-weight: 300;
`;
const XSlider = styled.div`
  transform: translate(-50%, -50%);
  left: 0px;
  top: 64px;
  position: absolute;
`;
const YSlider = styled.div`
  transform: translate(-50%, -50%);
  left: -10px;
  top: 267.1px;
  position: absolute;
`;
const Dot = styled.div`
  display: flex;
  background-color: #293b52;
  width: 25px;
  height: 25px;
  border-radius: 2px;
  color: white;
  cursor: pointer;
  justify-content: center;
  align-items: center;
`;
const HorizontalLine = styled.div`
  height: 3px;
  width: 667px;
  background-color: #db0a41;
  position: relative;
  top: -14px;
  z-index: -1;
  left: 6px;
`;
const VerticalLine = styled.div`
  height: 567px;
  width: 3px;
  background-color: #db0a41;
  position: relative;
  top: -14px;
  z-index: -1;
  left: 10px;
`;

const Alert = styled.div`
  display: flex;
  color: red;
`;

const Delete = styled.div`
  display: flex;
  color: #ff003b;
  cursor: pointer;
  min-width: 25px;
`;
const FormationNameContainer = styled.div`
  display: flex;
  align-items: center;
`;
const beach_formation = {
  name: "beach",
  rows: "122",
  positions: [
    { x: 0, y: 86 },
    {
      x: 27.076923076923073,
      y: 41.090909090909086,
    },
    {
      x: -26.730769230769234,
      y: 42.45454545454545,
    },
    {
      x: 26.961538461538456,
      y: -19.818181818181827,
    },
    {
      x: -26.346153846153847,
      y: -20.54545454545455,
    },
    { x: -21, y: -6 },
    { x: 0, y: 5 },
    { x: 21, y: -6 },
    { x: 45, y: -6 },
    { x: -18, y: -50 },
    { x: 19, y: -50 },
  ],
};

export default function FormationEditor({
  team,
  fixture,
  game,
  lineup,
  onSave,
  setSelectedFormation,
  selectedFormation,
}) {
  const [update, setUpdate] = useState(false);
  const [formations, setFormations] = useState();
  const [selectedSquares, setSelectedSquares] = useState([]);

  const [newFormation, setNewFormation] = useState();

  const [formationDelete, setFormationDelete] = useState();
  const [showConfirm, setShowConfirm] = useState();
  const {
    getLineup,
    getSquad,
    getFormation,
    updateFormation,
    updateGameFormation,
    createFormation,
    deleteFormation,
  } = useContext(DataContext);
  const { selectedSeason } = useContext(UIContext);
  let xScale = 7.8 / 1.5;
  let yScale = 3.3 / 1.5;

  function mouseMove(e) {
    if (window.formationDrag && window.formationDrag.down) {
      let diff = e.clientX - window.formationDrag.x;
      let diffY = e.clientY - window.formationDrag.y;

      selectedFormation.positions[window.formationDrag.index].x =
        window.formationDrag.start.x + diff / xScale;
      selectedFormation.positions[window.formationDrag.index].y =
        window.formationDrag.start.y - diffY / yScale;
      setUpdate(Date.now());
    } else if (window.yDrag && window.yDrag.down) {
      let diffY = e.clientY - window.yDrag.y;

      selectedSquares.forEach((sq) => {
        selectedFormation.positions[sq - 1].y =
          window.yDrag.start.y - diffY / yScale;
      });

      setUpdate(Date.now());
    } else if (window.xDrag && window.xDrag.down) {
      let diff = e.clientX - window.xDrag.x;

      selectedSquares.forEach((sq) => {
        selectedFormation.positions[sq - 1].x =
          window.xDrag.start.x + diff / xScale;
      });

      setUpdate(Date.now());
    }
  }

  function mouseUp(e) {
    console.log("up");
    window.formationDrag = { down: false };
    window.yDrag = { down: false };
    window.removeEventListener("mousemove", mouseMove);
    window.removeEventListener("mouseup", mouseUp);
    console.log(JSON.stringify(selectedFormation));
    setSelectedFormation(selectedFormation);
  }

  useEffect(() => {
    if (fixture && team && selectedSeason) {
      getFormations({ t: team });
    }
  }, [fixture, team, selectedSeason]);

  function getFormations({ t }) {
    getFormation({}).then((data) => {
      try {
        setSelectedFormation(
          JSON.parse(JSON.stringify(fixture[t + "_formation"]))
        );
      } catch (err) {}

      if (fixture?.competition?.competition_type !== "beach") {
        setFormations(data);
      } else {
        setFormations([beach_formation]);
        setSelectedFormation(beach_formation);
      }
    });
  }

  let number_of_players_on_pitch =
    fixture?.competition?.competition_type === "beach" ? 5 : 11;

  let number_of_players_in_squad =
    fixture?.competition?.competition_type === "beach" ? 12 : 16;

  return (
    <Container
      onClick={() => {
        setSelectedSquares([]);
      }}
    >
      {/* <Grid style={{ width: "100%" }}>
        <Grid.Unit size={10 / 10}>
          <Panel>
            <PanelTitle
              title={"Formation"}
            >
              <Buttons>


              </Buttons>
            </PanelTitle> */}
      <PanelContent style={{ flexDirection: "column" }}>
        <div style={{ display: "flex" }}>
          <div style={{ width: "70%" }}>
            <SelectUI
              value={{
                value: selectedFormation,
                label: selectedFormation?.name,
              }}
              options={
                formations &&
                formations.map((f) => {
                  return { value: f, label: f.name };
                })
              }
              placeholder="Select Formation"
              onChange={(formation) => {
                // updateGameFormation({
                //   fixtureId: fixture._id,
                //   team: team,
                //   formation: { ...formation },
                // }).then(() => {
                //   fixture.live_data[team + "_formation"] = {
                //     ...formation,
                //   };
                // });
                setSelectedFormation(JSON.parse(JSON.stringify(formation)));
                // updateGameData({
                //   _id: "game:" + fixture.id + ":team:" + team.id + ":formation",
                //   data: formation,
                //   type: "formation",
                // });
              }}
            />
          </div>

          {/* {selectedFormation && (
            <Button
              style={{ width: "30%" }}
              onClick={() => {
                setNewFormation({ ...selectedFormation });
              }}
            >
              Save as template
            </Button>
          )} */}
        </div>

        <div>
          <Input
            title="Rows"
            value={(selectedFormation && selectedFormation.rows) || ""}
            onChange={(e) => {
              let newFormation = { ...selectedFormation };
              newFormation.rows = e.currentTarget.value;
              setSelectedFormation(newFormation);
            }}
          />
        </div>

        <Main>
          <FormationImgDiv
            src={FormationImg}
            onDragStart={(e) => {
              e.preventDefault();
            }}
          />
          <FormationRows>
            {selectedSquares && selectedSquares.length > 0 && (
              <XSlider
                style={{
                  left:
                    selectedSquares &&
                    selectedSquares.length > 0 &&
                    selectedFormation &&
                    selectedFormation.positions[selectedSquares[0] - 1].x *
                      xScale +
                      "px",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onMouseDown={(e) => {
                  e.stopPropagation();
                  let position =
                    selectedFormation &&
                    selectedFormation.positions[selectedSquares[0] - 1];
                  window.xDrag = {
                    x: e.clientX,
                    y: e.clientY,
                    start: { ...position },
                    down: true,
                  };

                  window.addEventListener("mousemove", mouseMove);
                  window.addEventListener("mouseup", mouseUp);
                }}
              >
                <Dot>
                  <BsChevronDown />
                </Dot>
                <VerticalLine></VerticalLine>
              </XSlider>
            )}
            {selectedSquares && selectedSquares.length > 0 && (
              <YSlider
                onClick={(e) => {
                  e.stopPropagation();
                }}
                style={{
                  top:
                    selectedSquares &&
                    selectedSquares.length > 0 &&
                    selectedFormation &&
                    (Math.sign(
                      selectedFormation.positions[selectedSquares[0] - 1].y
                    ) == -1
                      ? Math.abs(
                          selectedFormation.positions[selectedSquares[0] - 1]
                            .y *
                            yScale -
                            5
                        )
                      : -selectedFormation.positions[selectedSquares[0] - 1].y *
                          yScale -
                        -5 +
                        "px"),
                }}
                onMouseDown={(e) => {
                  e.stopPropagation();
                  let position =
                    selectedFormation &&
                    selectedFormation.positions[selectedSquares[0] - 1];
                  window.yDrag = {
                    x: e.clientX,
                    y: e.clientY,
                    start: { ...position },
                    down: true,
                  };

                  window.addEventListener("mousemove", mouseMove);
                  window.addEventListener("mouseup", mouseUp);
                }}
              >
                <Dot>
                  <BsChevronRight />
                </Dot>
                <HorizontalLine></HorizontalLine>
              </YSlider>
            )}

            {formations &&
              selectedFormation &&
              selectedFormation.positions &&
              new Array(number_of_players_on_pitch)
                .fill(1)
                .map((item, index) => {
                  let player =
                    lineup && lineup.find((l) => l.order === index + 1);

                  let position =
                    selectedFormation && selectedFormation.positions[index];
                  let left = position.x;
                  let top =
                    Math.sign(position.y) == -1
                      ? Math.abs(position.y)
                      : -position.y;

                  //   let left = position.x;
                  //   let top =
                  //     Math.sign(position.y) == -1 ? Math.abs(position.y) : -position.y;

                  return (
                    <>
                      <Row
                        style={{
                          left: left * xScale + "px",
                          top: top * yScale + "px",
                        }}
                        clickable
                        onClick={(e) => {
                          e.stopPropagation();
                          if (e.ctrlKey) {
                            setSelectedSquares([...selectedSquares, index + 1]);
                          } else {
                            setSelectedSquares([index + 1]);
                          }
                        }}
                        onMouseDown={(e) => {
                          e.stopPropagation();
                          if (e.ctrlKey) {
                            setSelectedSquares([...selectedSquares, index + 1]);
                          } else {
                            setSelectedSquares([index + 1]);
                          }
                          window.formationDrag = {
                            x: e.clientX,
                            y: e.clientY,
                            index: index,
                            start: { ...position },
                            down: true,
                          };

                          window.addEventListener("mousemove", mouseMove);
                          window.addEventListener("mouseup", mouseUp);
                        }}
                      >
                        <PositionLabel>
                          x:{parseInt(left * xScale)} y:
                          {parseInt(top * yScale)}
                        </PositionLabel>
                        <Square
                          selected={selectedSquares.indexOf(index + 1) > -1}
                        >
                          <FormationNumber>{index + 1}</FormationNumber>
                          <div>
                            {player?.squad?.shirt_number ||
                              player?.squad?.opta_shirt_number ||
                              "-"}
                          </div>
                        </Square>
                        <LastName>
                          {player?.squad?.player?.last_name_upper || "----"}
                        </LastName>

                        {/* <HeadImg
                      src={
                        player &&
                        "http://opta.hyperstudios.live:3000/" +
                          team.name +
                          "/Home/Standard/" +
                          player.shirt_number +
                          ".png"
                      }
                      onError={(e) => {
                        e.target.src = "/HeadPlaceholder.png";
                      }}
                      onDragStart={(e) => {
                        e.preventDefault();
                      }}
                    />
                    <Name>
                      <Number>{player && player.order}</Number>

                      <LastName>
                        {player && player.last_name_upper}{" "}
                        {player && player.position === "Goalkeeper" && "(GK)"}
                        {player && player.captain && "(C)"}
                      </LastName>
                    </Name> */}
                      </Row>
                    </>
                  );
                })}
          </FormationRows>
          {/* {selectedFormation && (
            <Button
              style={{ marginTop: "70px", width: "100%" }}
              green
              onClick={() => {
                updateGameFormation({
                  fixtureId: fixture._id,
                  team: team,
                  formation: { ...selectedFormation },
                }).then(() => {
                  fixture.live_data[team + "_formation"] = {
                    ...selectedFormation,
                  };
                });
                onSave();
              }}
            >
              Save Changes
            </Button>
          )} */}
        </Main>
      </PanelContent>
      {/*     </Panel>
        </Grid.Unit>
      </Grid > */}
      {newFormation && (
        <Modal
          style={{ width: "50%", marginTop: "-25%" }}
          show={newFormation}
          onCancel={() => {
            setNewFormation();
          }}
        >
          <Panel style={{ height: "300px" }}>
            <PanelTitle title={"New Formation"}></PanelTitle>
            <PanelContent style={{ flexDirection: "column" }}>
              <Input
                title={"Name"}
                value={newFormation.name}
                onChange={(e) => {
                  setNewFormation({
                    ...newFormation,
                    name: e.currentTarget.value,
                  });
                }}
              />
              {formations.findIndex((f) => f.name === newFormation.name) >
                -1 && (
                <Alert>
                  This will overwrite the template {newFormation.name}. Enter a
                  different name to create a new template.
                </Alert>
              )}
            </PanelContent>
            <SavePanel
              style={{ marginLeft: "auto" }}
              showDelete={false}
              onSave={() => {
                if (
                  formations.findIndex((f) => f.name == newFormation.name) ===
                  -1
                ) {
                  delete newFormation._id;
                }
                updateFormation({ data: newFormation }).then(() => {
                  setNewFormation();
                  getFormations({ t: team });
                });
              }}
              onCancel={() => {
                setNewFormation();
              }}
            ></SavePanel>
          </Panel>
        </Modal>
      )}
    </Container>
  );
}
