import { getApolloContext } from "@apollo/client";
import React, { useState, useEffect, useContext } from "react";
import gql from "graphql-tag";

const FORMATIONS = gql`
  query Formations {
    formations {
      _id
      name
      rows
      positions
    }
  }
`;

const COMPETITIONS = gql`
  query Competitions {
    competitions {
      _id
      name
      description
      opta_ID
      formation
      code
      country
      country_code
      competition_type
      badge
      order
      colour
    }
  }
`;

const ADD_COMPETITION = gql`
  mutation AddCompetition(
    $name: String!
    $order: Int
    $badge: String
    $country: String
    $colour: String
  ) {
    addCompetition(
      name: $name
      order: $order
      badge: $badge
      country: $country
      colour: $colour
    ) {
      _id
    }
  }
`;
const DELETE_COMPETITION = gql`
  mutation DeleteCompetition($id: String!) {
    deleteCompetition(id: $id) {
      _id
    }
  }
`;

const UPDATE_COMPETITION = gql`
  mutation UpdateCompetition(
    $id: String!
    $name: String
    $order: Int
    $badge: String
    $country: String
    $colour: String
  ) {
    updateCompetition(
      id: $id
      name: $name
      order: $order
      badge: $badge
      country: $country
      colour: $colour
    ) {
      _id
    }
  }
`;

const ADD_SEASON = gql`
  mutation AddSeason($name: String!, $competition: String!) {
    addSeason(name: $name, competition: $competition) {
      _id
    }
  }
`;
const DELETE_SEASON = gql`
  mutation DeleteSeason($id: String!) {
    deleteSeason(id: $id) {
      _id
    }
  }
`;

const UPDATE_SEASON = gql`
  mutation UpdateSeason($id: String!, $name: String, $competition: String) {
    updateSeason(id: $id, name: $name, competition: $competition) {
      _id
    }
  }
`;

const SEASONS = gql`
  query Seasons {
    seasons {
      _id
      name
      competition {
        _id
        name
        competition_type
      }
      teams {
        _id
        name
        stadium {
          _id
          opta_name
          name
        }
      }
    }
  }
`;

const TEAMS = gql`
  query Teams {
    teams {
      _id
      name
      code
      short_name
      club_name
      type
      badge_name
      country
      status
      post_code
      address
      founded
      variables
    }
  }
`;

const ADD_TEAM = gql`
  mutation AddTeam(
    $name: String
    $short_name: String
    $club_name: String
    $code: String
    $variables: JSON
  ) {
    addTeam(
      name: $name
      short_name: $short_name
      club_name: $club_name
      code: $code
      variables: $variables
    ) {
      _id
    }
  }
`;

const ADD_TEAM_TO_SEASON = gql`
  mutation AddTeamToSeason($season: String!, $team: String!) {
    addTeamToSeason(season: $season, team: $team) {
      _id
    }
  }
`;

const UPDATE_TEAM = gql`
  mutation UpdateTeam(
    $id: String!
    $name: String
    $short_name: String
    $club_name: String
    $code: String
    $stadium: String
    $variables: JSON
  ) {
    updateTeam(
      id: $id
      name: $name
      short_name: $short_name
      club_name: $club_name
      code: $code
      stadium: $stadium
      variables: $variables
    ) {
      _id
    }
  }
`;

const ADD_PLAYER = gql`
  mutation AddPlayer(
    $first_name: String
    $first_name_upper: String
    $last_name: String
    $last_name_upper: String
    $nationality: String
    $title: String
  ) {
    addPlayer(
      first_name: $first_name
      first_name_upper: $first_name_upper
      last_name: $last_name
      last_name_upper: $last_name_upper
      nationality: $nationality
      title: $title
    ) {
      _id
    }
  }
`;

const ADD_PLAYER_TO_SQUAD = gql`
  mutation AddPlayerToSquad(
    $player: String!
    $season: String!
    $team: String!
    $type: String!
    $shirt_number: Int
    $position: String
  ) {
    addPlayerToSquad(
      player: $player
      season: $season
      team: $team
      type: $type
      shirt_number: $shirt_number
      position: $position
    ) {
      _id
    }
  }
`;

const UPDATE_SQUAD_PLAYER = gql`
  mutation UpdateSquadPlayer(
    $id: String!
    $shirt_number: Int
    $position: String
  ) {
    updateSquadPlayer(
      id: $id
      shirt_number: $shirt_number
      position: $position
    ) {
      _id
    }
  }
`;

const UPDATE_PLAYER = gql`
  mutation UpdatePlayer(
    $id: String!
    $first_name: String
    $first_name_upper: String
    $last_name: String
    $last_name_upper: String
    $nationality: String
    $title: String
  ) {
    updatePlayer(
      id: $id
      first_name: $first_name
      first_name_upper: $first_name_upper
      last_name: $last_name
      last_name_upper: $last_name_upper
      nationality: $nationality
      title: $title
    ) {
      _id
    }
  }
`;

const DELETE_PLAYER = gql`
  mutation DeletePlayer($id: String!) {
    deletePlayer(id: $id) {
      _id
    }
  }
`;

const NATIONS = gql`
  query Nations {
    nations {
      _id
      name
      opta_ID
      opta_code
      opta_name
    }
  }
`;

const REMOVE_PLAYER_FROM_SQUAD = gql`
  mutation RemovePlayerFromSquad($id: String!) {
    removePlayerFromSquad(id: $id) {
      _id
    }
  }
`;

const SQUAD_BY_TEAM = gql`
  query Squad($teamId: String, $seasonId: String) {
    squadByTeam(teamId: $teamId, seasonId: $seasonId) {
      _id
      active
      type
      shirt_number
      opta_shirt_number
      position
      player {
        _id
        opta_ID
        first_name
        last_name
        first_name_upper
        last_name_upper
        title
        nationality {
          _id
          opta_code
          opta_name
        }
      }
      team {
        name
      }
    }
  }
`;
const GAMES_BY_DATE = gql`
  query Games($date: String) {
    gamesByDate(date: $date) {
      _id
      time
      description
      date
      stadium {
        _id
        opta_name
        name
      }
      competition {
        name
        _id
        competition_type
      }
      opta_lineup {
        home {
          squad {
            _id
            opta_shirt_number
            player {
              _id
              first_name
              last_name
              first_name_upper
              last_name_upper
              nationality {
                _id
                opta_code
                opta_name
              }
            }
          }
          order
        }
        away {
          squad {
            _id
            opta_shirt_number
            player {
              _id
              first_name
              last_name
              first_name_upper
              last_name_upper
              nationality {
                _id
                opta_code
                opta_name
              }
            }
          }
          order
        }
      }
      lineup {
        home {
          squad {
            _id
            opta_shirt_number
            player {
              _id
              first_name
              last_name
              first_name_upper
              last_name_upper
              nationality {
                _id
                opta_code
                opta_name
              }
            }
          }
          order
          subbed
          sub_order
          captain
        }
        away {
          squad {
            _id
            opta_shirt_number
            player {
              _id
              first_name
              last_name
              first_name_upper
              last_name_upper
              nationality {
                _id
                opta_code
                opta_name
              }
            }
          }
          order
          subbed
          sub_order
          captain
        }
      }
      home_team {
        name
        code
      }
      away_team {
        name
        code
      }
      live_data
      home_formation
      away_formation
    }
  }
`;

const GAME_BY_ID = gql`
  query Game($id: String) {
    gameById(id: $id) {
      _id
      time
      description
      date
      stadium {
        _id
        opta_name
        name
      }
      officials {
        official {
          first_name
          last_name
        }
        type
      }
      competition {
        name
        _id
        competition_type
      }
      lineup {
        home {
          squad {
            _id
            opta_shirt_number
            player {
              _id
              first_name
              last_name
              first_name_upper
              last_name_upper
              nationality {
                _id
                opta_code
                opta_name
              }
            }
          }
          order
        }
        away {
          squad {
            _id
            opta_shirt_number
            player {
              _id
              first_name
              last_name
              first_name_upper
              last_name_upper
              nationality {
                _id
                opta_code
                opta_name
              }
            }
          }
          order
        }
      }
      opta_lineup {
        home {
          squad {
            _id
            opta_shirt_number
            player {
              _id
              first_name
              last_name
              first_name_upper
              last_name_upper
              nationality {
                _id
                opta_code
                opta_name
              }
            }
          }
          order
        }
        away {
          squad {
            _id
            opta_shirt_number
            player {
              _id
              first_name
              last_name
              first_name_upper
              last_name_upper
              nationality {
                _id
                opta_code
                opta_name
              }
            }
          }
          order
        }
      }
      home_team {
        _id
        name
        code
      }
      away_team {
        _id
        name
        code
      }
      live_data
      home_formation
      away_formation
      season {
        _id
      }
    }
  }
`;

const ADD_GAME = gql`
  mutation AddGame(
    $competition: String!
    $season: String!
    $home_team: String!
    $away_team: String!
    $date: String!
    $time: String!
    $stadium: String!
    $description: String
  ) {
    addGame(
      competition: $competition
      season: $season
      home_team: $home_team
      away_team: $away_team
      date: $date
      time: $time
      stadium: $stadium
      description: $description
    ) {
      _id
    }
  }
`;

const EDIT_GAME = gql`
  mutation EditGame(
    $competition: String!
    $season: String!
    $home_team: String!
    $away_team: String!
    $date: String!
    $time: String!
    $stadium: String!
    $description: String
    $id: String!
  ) {
    editGame(
      competition: $competition
      season: $season
      home_team: $home_team
      away_team: $away_team
      date: $date
      time: $time
      stadium: $stadium
      description: $description
      id: $id
    ) {
      _id
    }
  }
`;

const STADIUM_BY_ID = gql`
  query Stadium($id: String) {
    stadiumById(id: $id) {
      _id
      name
      opta_name
      opta_ID
    }
  }
`;

const STADIUMS = gql`
  query Stadiums {
    stadiums {
      _id
      name
      opta_name
      opta_ID
    }
  }
`;

const OFFICIALS = gql`
  query Officials {
    officials {
      _id
      first_name
      last_name
      opta_ID
    }
  }
`;

const ADD_GROUP = gql`
  mutation AddGroup($competition: String!, $season: String!, $title: String!) {
    addGroup(competition: $competition, season: $season, title: $title) {
      _id
    }
  }
`;

const GROUPS = gql`
  query Groups {
    groups {
      _id
      title
      competition {
        _id
        name
      }
      season {
        _id
        name
      }
      teams {
        team {
          _id
          name
          code
        }
        played
        points
        won
        lost
        drawn
      }
    }
  }
`;

const GROUP_BY_ID = gql`
  query Groups($id: String!) {
    groupById(id: $id) {
      _id
      title
      competition {
        _id
        name
      }
      season {
        _id
        name
      }
      teams {
        team {
          _id
          name
          code
        }
        played
        points
        won
        lost
        drawn
      }
    }
  }
`;

const GROUPS_BY_SEASON = gql`
  query GroupsBySeason($season: String!) {
    groupsBySeason(season: $season) {
      _id
      title
      competition {
        _id
        name
      }
      season {
        _id
        name
      }
      teams {
        team {
          _id
          name
          code
        }
        played
        points
        won
        lost
        drawn
      }
    }
  }
`;

const UPDATE_GROUP = gql`
  mutation UpdateGroup($id: String!, $title: String, $teams: [String]) {
    updateGroup(id: $id, title: $title, teams: $teams) {
      _id
    }
  }
`;

const UPDATE_GAME = gql`
  mutation UpdateGame($id: String!, $live_data: JSON) {
    updateGame(id: $id, live_data: $live_data) {
      live_data
    }
  }
`;

const UPDATE_COMMENTATORS = gql`
  mutation UpdateCommentators($id: String!, $commentators: JSON) {
    updateGame(id: $id, commentators: $commentators) {
      _id
    }
  }
`;

const UPDATE_HOME_FORMATION = gql`
  mutation UpdateFormation($id: String!, $formation: JSON) {
    updateGame(id: $id, home_formation: $formation) {
      _id
    }
  }
`;

const UPDATE_AWAY_FORMATION = gql`
  mutation UpdateFormation($id: String!, $formation: JSON) {
    updateGame(id: $id, away_formation: $formation) {
      _id
    }
  }
`;

const UPDATE_LINEUP = gql`
  mutation UpdateLineup($id: String!, $lineup: JSON) {
    updateGame(id: $id, lineup: $lineup) {
      _id
    }
  }
`;

const UPDATE_HOME_KIT = gql`
  mutation UpdateKit($id: String!, $kit: String!) {
    updateGame(id: $id, home_kit: $kit) {
      home_team_kit
    }
  }
`;

const UPDATE_AWAY_KIT = gql`
  mutation UpdateKit($id: String!, $kit: String!) {
    updateGame(id: $id, away_kit: $kit) {
      away_team_kit
    }
  }
`;

const ADD_STADIUM = gql`
  mutation addStadium($name: String) {
    addStadium(name: $name) {
      _id
    }
  }
`;

const UPDATE_STADIUM = gql`
  mutation UpdateStadium($id: String!, $name: String) {
    updateStadium(id: $id, name: $name) {
      _id
    }
  }
`;

const DataContext = React.createContext([{}, () => {}]);
const server = window.location.protocol + "//" + window.SERVER + ":8082";
window.DATA_SERVER = server;
const DataProvider = (props) => {
  const { client } = useContext(getApolloContext());

  function getSecondSpectrumAveragePositions({ gameId }) {
    return fetch(server + "/hyper/v1/second_spectrum/shape_summary/" + gameId)
      .then((response) => response.text())
      .then((data) => {
        let arr = data.split(/\r?\n/);
        return arr.map((item) => JSON.parse(item));
      });
  }

  function getSeason(seasonId) {
    return client
      .query({
        query: SEASONS,
        fetchPolicy: "network-only",
      })
      .then((response) => {
        if (seasonId) {
          return response?.data?.seasons?.find((s) => s._id === seasonId);
        } else {
          return response.data.seasons;
        }
      })
      .catch((err) => console.error(err));
  }
  function getOfficial(officialId) {
    if (officialId) {
    } else {
      return client
        .query({
          query: OFFICIALS,
        })
        .then((response) => {
          return response.data.officials;
        })
        .catch((err) => console.error(err));
    }
  }

  function createGroup({ group }) {
    return client
      .mutate({
        mutation: ADD_GROUP,
        variables: {
          competition: group.competition,
          season: group.season,
          title: group.title,
        },
      })
      .then((response) => {
        return response.data.addGroup;
      })
      .catch((err) => console.error(err));
  }

  function updateGroup(group) {
    return client
      .mutate({
        mutation: UPDATE_GROUP,
        variables: {
          id: group._id,
          title: group.title,
          teams: group.teams,
        },
      })
      .then((response) => {
        return response.data.updateGroup;
      })
      .catch((err) => console.error(err));
  }

  function getGroup(groupId) {
    if (groupId) {
      return client
        .query({
          query: GROUP_BY_ID,
          fetchPolicy: "network-only",
          variables: {
            id: groupId,
          },
        })
        .then((response) => {
          return response.data.groupById;
        })
        .catch((err) => console.error(err));
    } else {
      return client
        .query({
          query: GROUPS,
        })
        .then((response) => {
          return response.data.groups;
        })
        .catch((err) => console.error(err));
    }
  }
  function getGroupsBySeason({ seasonId }) {
    if (seasonId) {
      return client
        .query({
          query: GROUPS_BY_SEASON,
          fetchPolicy: "network-only",
          variables: {
            season: seasonId,
          },
        })
        .then((response) => {
          return response.data.groupsBySeason;
        })
        .catch((err) => console.error(err));
    }
  }
  function getStadium(stadiumId) {
    if (stadiumId) {
      return client
        .query({
          query: STADIUM_BY_ID,
          fetchPolicy: "network-only",
          variables: {
            id: stadiumId,
          },
        })
        .then((response) => {
          return response.data.stadiumById;
        })
        .catch((err) => console.error(err));
    } else {
      return client
        .query({
          query: STADIUMS,
          fetchPolicy: "network-only",
        })
        .then((response) => {
          return response.data.stadiums;
        })
        .catch((err) => console.error(err));
    }
  }

  function deleteCompetition({ data }) {
    return client
      .mutate({
        mutation: DELETE_COMPETITION,
        variables: {
          id: data._id,
        },
      })
      .then((response) => {
        return response.data.deleteCompetition;
      })
      .catch((err) => console.error(err));
  }

  function getCompetition(compId) {
    return client
      .query({
        query: COMPETITIONS,
        fetchPolicy: "network-only",
      })
      .then((response) => {
        return response.data.competitions;
      })
      .catch((err) => console.error(err));
  }

  function updateCompetition({ data }) {
    if (data._id) {
      return client
        .mutate({
          mutation: UPDATE_COMPETITION,
          variables: {
            id: data._id,
            name: data.name,
            order: parseInt(data.order),
            badge: data.badge,
            country: data.country,
            colour: data.colour,
          },
        })
        .then((response) => {
          return response.data.updateCompetition;
        })
        .catch((err) => console.error(err));
    } else {
      return client
        .mutate({
          mutation: ADD_COMPETITION,
          variables: {
            name: data.name,
            order: parseInt(data.order),
            badge: data.badge,
            country: data.country,
            colour: data.colour,
          },
        })
        .then((response) => {
          return response.data.addCompetition;
        })
        .catch((err) => console.error(err));
    }
  }

  function deleteSeason({ data }) {
    return client
      .mutate({
        mutation: DELETE_SEASON,
        variables: {
          id: data._id,
        },
      })
      .then((response) => {
        return response.data.deleteSeason;
      })
      .catch((err) => console.error(err));
  }

  function updateSeason({ data }) {
    if (data._id) {
      return client
        .mutate({
          mutation: UPDATE_SEASON,
          variables: {
            id: data._id,
            name: data.name,
            competition: data.competition,
          },
        })
        .then((response) => {
          return response.data.updateSeason;
        })
        .catch((err) => console.error(err));
    } else {
      return client
        .mutate({
          mutation: ADD_SEASON,
          variables: {
            id: data._id,
            name: data.name,
            competition: data.competition,
          },
        })
        .then((response) => {
          return response.data.addSeason;
        })
        .catch((err) => console.error(err));
    }
  }

  function updatePlayer(data) {
    if (data._id) {
      return client
        .mutate({
          mutation: UPDATE_PLAYER,
          variables: {
            id: data._id,
            first_name: data.first_name,
            first_name_upper: data.first_name_upper,
            last_name: data.last_name,
            last_name_upper: data.last_name_upper,
            nationality: data.nationality?._id,
            title: data.title,
          },
        })
        .then((response) => {
          return response.data.updatePlayer;
        })
        .catch((err) => console.error(err));
    } else {
      return client
        .mutate({
          mutation: ADD_PLAYER,
          variables: {
            first_name: data.first_name,
            first_name_upper: data.first_name_upper,
            last_name: data.last_name,
            last_name_upper: data.last_name_upper,
            nationality: data.nationality?._id,
            title: data.title,
          },
        })
        .then((response) => {
          return response.data.addPlayer;
        })
        .catch((err) => console.error(err));
    }
  }

  function deletePlayer({ playerId }) {
    return client
      .mutate({
        mutation: DELETE_PLAYER,
        variables: {
          id: playerId,
        },
      })
      .then((response) => {
        return response.data.deletePlayer;
      })
      .catch((err) => console.error(err));
  }

  function updateSquadPlayer(player) {
    return client
      .mutate({
        mutation: UPDATE_SQUAD_PLAYER,
        variables: {
          id: player._id,
          shirt_number: parseInt(player.shirt_number),
          position: player.position,
        },
      })
      .then((response) => {
        return response.data.deletePlayer;
      })
      .catch((err) => console.error(err));
  }

  function getSquads() {
    return fetch(server + "/squads/")
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
  }

  function addToSquad({
    playerId,
    teamId,
    seasonId,
    shirt_number,
    position,
    type = "player",
  }) {
    if (playerId) {
      return client
        .mutate({
          mutation: ADD_PLAYER_TO_SQUAD,
          variables: {
            player: playerId,
            team: teamId,
            season: seasonId,
            shirt_number: parseInt(shirt_number),
            position,
            type,
          },
        })
        .then((response) => {
          return response.data.addPlayerToSquad;
        })
        .catch((err) => console.error(err));
    }
  }

  function getTeams() {
    return client
      .query({
        query: TEAMS,
        fetchPolicy: "network-only",
      })
      .then((response) => {
        return response.data.teams;
      })
      .catch((err) => console.error(err));
  }

  function updateTeam(team) {
    return client
      .mutate({
        mutation: UPDATE_TEAM,
        variables: {
          id: team._id,
          name: team.name,
          short_name: team.short_name,
          club_name: team.club_name,
          code: team.code,
          variables: team.variables,
          stadium: team.stadium?._id,
        },
      })
      .then((response) => {
        return response.data.updateTeam;
      })
      .catch((err) => console.error(err));
  }

  function getFixture({ fixtureId }) {
    return client
      .query({
        query: GAME_BY_ID,
        fetchPolicy: "network-only",
        variables: {
          id: fixtureId,
        },
      })
      .then((response) => {
        return response.data.gameById;
      })
      .catch((err) => console.error(err));
  }

  function getCountry({ countryId }) {
    return client
      .query({
        query: NATIONS,
      })
      .then((response) => {
        return response.data.nations;
      })
      .catch((err) => console.error(err));
  }

  function getSquadPlayers({ teamId, seasonId, coach }) {
    return client
      .query({
        query: SQUAD_BY_TEAM,
        fetchPolicy: "network-only",
        variables: {
          teamId: teamId,
          seasonId: seasonId,
        },
      })
      .then((response) => {
        return response.data.squadByTeam;
      })
      .catch((err) => console.error(err));
  }

  function getCoach({ teamId, seasonId }) {
    return getCountry({}).then((countries) => {
      return getSquadPlayers({
        teamId: teamId,
        seasonId: seasonId,
        coach: true,
      }).then((coach) => {
        if (coach.length > 0) {
          return fetch(server + "/players/" + coach[0].player)
            .then((response) => response.json())
            .then((data) => {
              return {
                ...coach.find((s) => s.player === data._id),
                ...data,
                nationality: countries.find((c) => c._id === data.nationality),
              };
            });
        }
      });
    });
  }

  function getSquad({ teamId, seasonId }) {
    return getSquadPlayers({ teamId: teamId, seasonId: seasonId });
  }

  function getLineup({ fixtureId, team }) {
    return getCountry({}).then((countries) => {
      return getFixture({ fixtureId: fixtureId }).then((fixture) => {
        let team_lineup = fixture.live_data[`${team}_team_lineup`];
        let url = "";
        return getSquadPlayers({
          teamId: fixture[team + "_team"],
          seasonId: fixture.season,
        }).then((squadPlayers) => {
          if (team_lineup && team_lineup.length > 1) {
            url =
              server +
              "/players/?$orderby=last_name&$filter=_id $in " +
              team_lineup.map((d) => d.player).join(",");
          } else if (team_lineup && team_lineup.length > 0) {
            url = server + "/players/" + team_lineup[0].player;
          }

          if (url === "") {
            return Promise.resolve();
          }

          return fetch(url)
            .then((response) => response.json())
            .then((data) => {
              if (Array.isArray(data)) {
                return team_lineup.map((l) => {
                  let player = data.find((p) => p._id === l.player);
                  return {
                    ...l,
                    ...squadPlayers.find((p) => p.player === l.player),
                    ...player,
                    nationality: countries.find(
                      (c) => c._id === player.nationality
                    ),
                  };
                });
              } else {
                return team_lineup.map((l) => {
                  return {
                    ...l,
                    ...squadPlayers.find((p) => p.player === l.player),
                    ...data,
                    nationality: countries.find(
                      (c) => c._id === data.nationality
                    ),
                  };
                });
              }
            });
        });
      });
    });
  }

  function getPlayersBySearch({ search, count, limit, page }) {
    let url = server + "/players?$filter=last_name $regex '^" + search + "'";
    if (count) {
      url += "&$count";
    }
    if (limit) {
      url += "&$limit=" + limit;
    }
    if (page) {
      url += "&$skip=" + page;
    }

    return fetch(url)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
  }

  function getPlayer({ playerId }) {
    return fetch(server + "/players/" + (playerId || ""))
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
  }

  function editFixture({ fixture }) {
    return client
      .mutate({
        mutation: EDIT_GAME,
        variables: {
          id: fixture.id,
          competition: fixture.competition,
          season: fixture.season,
          home_team: fixture.home_team,
          away_team: fixture.away_team,
          date: fixture.date,
          time: fixture.time,
          stadium: fixture.stadium,
          description: fixture.description,
        },
      })
      .then((response) => {
        return response.data.editGame;
      })
      .catch((err) => console.error(err));
  }

  function createFixture({ fixture }) {
    return client
      .mutate({
        mutation: ADD_GAME,
        variables: {
          competition: fixture.competition,
          season: fixture.season,
          home_team: fixture.home_team,
          away_team: fixture.away_team,
          date: fixture.date,
          time: fixture.time,
          stadium: fixture.stadium,
          description: fixture.description,
        },
      })
      .then((response) => {
        return response.data.addGame;
      })
      .catch((err) => console.error(err));
  }

  function deleteFixture({ fixtureId }) {
    return fetch(server + "/games/" + fixtureId, {
      method: "DELETE",
    });
  }

  function deleteSquadPlayer({ playerId }) {
    return client
      .mutate({
        mutation: REMOVE_PLAYER_FROM_SQUAD,
        variables: {
          id: playerId,
        },
      })
      .then((response) => {
        return response.data.removePlayerFromSquad;
      })
      .catch((err) => console.error(err));
  }

  function updateOfficial({ data }) {
    return fetch(server + "/officials/" + data._id, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }

  function updateGame({ id, live_data }) {
    return client
      .mutate({
        mutation: UPDATE_GAME,
        variables: { id, live_data },
      })
      .then((response) => {
        return response.data.updateGame;
      })
      .catch((err) => console.error(err));
  }

  function updateKit({ id, kit, team }) {
    debugger;
    return client
      .mutate({
        mutation: team === "home" ? UPDATE_HOME_KIT : UPDATE_AWAY_KIT,
        variables: { id, kit },
      })
      .then((response) => {
        return response.data.updateGame;
      })
      .catch((err) => console.error(err));
  }

  function updateLineup({ id, lineup }) {
    return client
      .mutate({
        mutation: UPDATE_LINEUP,
        variables: { id, lineup },
      })
      .then((response) => {
        return response.data.updateLineup;
      })
      .catch((err) => console.error(err));
  }

  function updateGameOfficial({ fixtureId, official, type }) {
    return fetch(server + "/games/" + fixtureId, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        $set: { [type]: official._id },
      }),
    });
  }

  function updateGameCommentator({ fixtureId, commentator }) {
    return fetch(server + "/games/" + fixtureId, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        $set: { "variables.commentator": commentator },
      }),
    });
  }

  function updateGameSecondSpectrum({ fixtureId, secondSpectrumID }) {
    return fetch(server + "/games/" + fixtureId, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        $set: { "variables.second_spectrum_id": secondSpectrumID },
      }),
    });
  }

  function updateHeadCoach({ teamId, coach }) {
    return fetch(server + "/teams/" + teamId, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        $set: { coach: coach },
      }),
    });
  }

  function getTransfer({ transferId }) {
    return fetch(server + "/transfers/" + (transferId || ""))
      .then((response) => response.json())
      .then(async (data) => {
        for (let i = 0; i < data.length; i++) {
          data[i] = {
            ...data[i],
            player: await getPlayer({ playerId: data[i].player }),
          };
        }
        return data;
      });
  }

  function getFormation({ formation }) {
    return client
      .query({
        query: FORMATIONS,
        fetchPolicy: "network-only",
      })
      .then((response) => {
        return response.data.formations;
      })
      .catch((err) => console.error(err));
  }

  function updateFormation({ data, gameId }) {
    if (data._id) {
      return fetch(server + "/formations/" + data._id, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
    } else {
      return fetch(server + "/formations/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
    }
  }

  function updateGameFormation({ id, team, formation }) {
    return client
      .mutate({
        mutation:
          team === "home" ? UPDATE_HOME_FORMATION : UPDATE_AWAY_FORMATION,
        variables: { id, formation },
      })
      .then((response) => {
        return response.data.updateGame;
      })
      .catch((err) => console.error(err));
  }

  function updateGameCommentators({ id, commentators }) {
    return client
      .mutate({
        mutation: UPDATE_COMMENTATORS,
        variables: { id, commentators },
      })
      .then((response) => {
        return response.data.updateGame;
      })
      .catch((err) => console.error(err));
  }

  function updateStatBox(box, newPage) {
    box = { ...box, season: box.season._id || box.season };
    if (box._id && !newPage) {
      return fetch(server + "/statboxs/" + box._id, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(box),
      });
    } else {
      delete box._id;
      return fetch(server + "/statboxs/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(box),
      });
    }
  }

  function getStatBox({ id }) {
    return fetch(server + "/statboxs/" + (id || "?$orderby=name"))
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
  }

  function getStandings({ season }) {
    let url = server + "/standings?$single&$filter=season $eq " + season;
    return fetch(url)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
  }

  function deleteStatBox({ id }) {
    return fetch(server + "/statboxs/" + id, { method: "DELETE" });
  }

  function createFormation({ data }) {
    delete data._id;
    return fetch(server + "/formations/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }

  function deleteFormation({ data }) {
    return fetch(server + "/formations/" + data._id, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }

  function updateStadium({ data }) {
    if (data._id) {
      return client
        .mutate({
          mutation: UPDATE_STADIUM,
          variables: { id: data._id, name: data.name },
        })
        .then((response) => {
          return response.data.updateStadium;
        })
        .catch((err) => console.error(err));
    } else {
      return client
        .mutate({
          mutation: ADD_STADIUM,
          variables: { name: data.name },
        })
        .then((response) => {
          return response.data.addStadium;
        })
        .catch((err) => console.error(err));
    }
  }

  function updateCountry({ data }) {
    if (data._id) {
      return fetch(server + "/nations/" + data._id, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
    } else {
      return fetch(server + "/nations/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
    }
  }

  function testOPTA({ data }) {
    return fetch(server + "/hyper/v1/testOPTA", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }

  function getUser({ id }) {
    return fetch(server + "/users/" + (id || "?$orderby=first_name"))
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
  }

  function updateUser({ data }) {
    if (data._id) {
      return fetch(server + "/users/" + data._id, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
    } else {
      return fetch(server + "/users/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
    }
  }

  function deleteUser({ data }) {
    return fetch(server + "/users/" + data._id, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  function downloadDatabase() {
    return fetch(server + "/hyper/v1/database/backup", {
      method: "GET",
    });
  }

  function getServerConfig() {
    return fetch(server + "/hyper/v1/server/config")
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
  }

  function getPossession({ id }) {
    return fetch(server + "/possession?$filter=opta_ID $eq " + id)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
  }

  function getSettings() {
    return fetch(server + "/settings")
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
  }

  function updateSettings({ data }) {
    if (data._id) {
      return fetch(server + "/settings/" + data._id, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
    } else {
      return fetch(server + "/settings/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
    }
  }

  function deleteSettings({ data }) {
    return fetch(server + "/settings/" + data._id, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }

  function getFixtures({ competition, date }) {
    return client
      .query({
        query: GAMES_BY_DATE,
        variables: { date: date + "Z" },
        fetchPolicy: "network-only",
      })
      .then((response) => {
        return response.data.gamesByDate;
      })
      .catch((err) => console.error(err));
  }

  function mergePlayers({ manual_player, opta_player }) {
    let player = {
      opta_first_name: opta_player.opta_first_name,
      opta_last_name: opta_player.opta_last_name,
      opta_ID: opta_player.opta_ID,
      date_of_birth: opta_player.date_of_birth,
      height: opta_player.height,
      weight: opta_player.weight,
      first_name: manual_player.first_name,
      last_name: manual_player.last_name,
      first_name_upper: manual_player.first_name_upper,
      last_name_upper: manual_player.last_name_upper,
      nationality: manual_player.nationality,
      stories: manual_player.stories,
      head_shots: manual_player.head_shots,
      _id: manual_player._id,
    };

    return deleteSquadPlayer({ playerId: opta_player.squad_id }).then(() => {
      return deletePlayer({ playerId: opta_player._id }).then(() => {
        return updatePlayer(player);
      });
    });
  }

  function createTeam({ data }) {
    return client
      .mutate({
        mutation: ADD_TEAM,
        variables: {
          name: data.name,
          short_name: data.short_name,
          club_name: data.club_name,
          code: data.code,
          variables: data.variables,
        },
      })
      .then((response) => {
        return response.data.addTeam;
      })
      .catch((err) => console.error(err));
  }

  function addTeamToSeason({ season, team }) {
    return client
      .mutate({
        mutation: ADD_TEAM_TO_SEASON,
        variables: {
          season: season,
          team: team,
        },
      })
      .then((response) => {
        return response.data.addTeamToSeason;
      })
      .catch((err) => console.error(err));
  }

  return (
    <DataContext.Provider
      value={{
        updatePlayer,
        updateLineup,
        getLineup,
        getFixture,
        getTeams,
        getSquad,
        getPlayersBySearch,
        getSquads,
        createFixture,
        deleteFixture,
        getOfficial,
        updateGameOfficial,
        updateHeadCoach,
        getCompetition,
        getSeason,
        getStadium,
        getOfficial,
        getCoach,
        getTransfer,
        updateTeam,
        getCountry,
        getUser,
        addToSquad,
        deleteSquadPlayer,
        getFormation,
        updateFormation,
        updateGameFormation,
        updateStatBox,
        getStatBox,
        deleteStatBox,
        getStandings,
        updateSquadPlayer,
        createFormation,
        deleteFormation,
        updateStadium,
        testOPTA,
        updateCountry,
        getUser,
        updateUser,
        deleteUser,
        downloadDatabase,
        getServerConfig,
        updateOfficial,
        mergePlayers,
        updateCompetition,
        getSettings,
        updateSettings,
        deleteSettings,
        getPossession,
        updateGameCommentator,
        getSecondSpectrumAveragePositions,
        updateGameSecondSpectrum,
        getFixtures,
        deleteCompetition,
        updateSeason,
        deleteSeason,
        createTeam,
        addTeamToSeason,
        updateGame,
        updateLineup,
        updateKit,
        updateGameCommentators,
        editFixture,
        getGroup,
        createGroup,
        getGroupsBySeason,
        updateGroup,
      }}
    >
      {props.children}
    </DataContext.Provider>
  );
};
export { DataContext, DataProvider };
