import React from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import styled from "styled-components";

const Main = styled.div`
  border-color: #dee2e6;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 3px !important;
  width: 100%;
  min-height: 100px;
`;
const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  > tbody > tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }

  th {
    padding: 10px;
    border-top: 1px solid #dee2e6;
  }
  td {
  }
  > tbody > tr:hover {
    background-color: #14ec8e;
    color: white;
    cursor: pointer;
  }
`;

const TH = styled.th`
  border-top: 0;
  border-bottom: 0;
  font-weight: 800;
  font-size: 0.8em;
  text-transform: uppercase;
  color: #343a40;
  text-align: left;
`;

const TD = styled.td`
  padding: 10px;
  width: ${(props) => props.width};
  background-color: ${(props) => (props.green === true ? "green" : "")};
  height: 32px;
`;

const Off = styled(IoIosArrowDown)`
  color: #db0a41;
  font-size: 20px;
`;
const On = styled(IoIosArrowUp)`
  color: #0adb93;
  font-size: 20px;
`;
export default function Lineup({
  squad,
  selectPlayer,
  style,
  showOrder,
  showHeader,
  swap,
  addPlayerAtOrder,
  selectCaptain,
  number_of_players_on_pitch,
}) {
  return (
    <Main style={style}>
      <Table>
        <thead>
          {showHeader && (
            <tr>
              {showOrder && <TH></TH>}
              <TH>#</TH>
              <TH>First Name</TH>
              <TH>Last Name</TH>

              <TH>Captain</TH>
              {/* <TH></TH> */}
              {/* {!showOrder && <TH>Initial</TH>} */}
            </tr>
          )}
        </thead>
        <tbody>
          {squad?.map((squadPlayer, index) => {
            let squad = {
              ...(squadPlayer?.squad || squadPlayer),
              order: squadPlayer.order,
              yellow_card: squadPlayer.yellow_card,
              dbl_yellow_card: squadPlayer.dbl_yellow_card,
              red_card: squadPlayer.red_card,
              subbed: squadPlayer.subbed,
              captain: squadPlayer.captain,
            };

            let player = squad.player;
            return (
              <tr
                key={index}
                draggable={true}
                style={{ userSelect: "none" }}
                onDragStart={(e) => {
                  e.dataTransfer.setData("player", squad._id);
                  e.dataTransfer.setData("replace", showOrder);
                }}
                onDragOver={(ev) => {
                  ev.preventDefault();
                }}
                onDrop={(e) => {
                  let playerId = e.dataTransfer.getData("player");
                  let replace = e.dataTransfer.getData("replace") === "true";
                  if (replace) {
                    addPlayerAtOrder(playerId, squad.order || index);
                  } else {
                    swap(playerId, squad._id || "", squad.order);
                  }
                }}
              >
                {showOrder && <TD index={index}>{player && player.order}</TD>}
                <TD index={index}>{squad?.shirt_number}</TD>
                <TD
                  index={index}
                  width={"50%"}
                  onClick={() => {
                    selectPlayer(squadPlayer);
                  }}
                >
                  {player && player.first_name}
                </TD>
                <TD
                  index={index}
                  width={"100%"}
                  onClick={() => {
                    selectPlayer(squadPlayer);
                  }}
                >
                  {player && player.last_name}
                </TD>
                {!showOrder && (
                  <TD>
                    <input
                      type="checkbox"
                      checked={squad?.captain}
                      onChange={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        selectCaptain(squadPlayer, e.currentTarget.checked);
                      }}
                    />
                  </TD>
                )}
                <TD>
                  {squad?.subbed &&
                    squad?.order <= number_of_players_on_pitch && <On />}
                  {squad?.subbed &&
                    squad?.order > number_of_players_on_pitch && <Off />}
                </TD>

                {/* <TD>
                  {squad?.yellow_card ? "yellow" : ""}
                  {squad?.dbl_yellow_card ? "dbl yellow" : ""}
                  {squad?.red_card ? "red" : ""}
                </TD> */}
                {/* {!showOrder && (
                    <TD
                      index={index}
                      width={"100%"}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <input type="checkbox" onChange={() => {}} />
                    </TD>
                  )} */}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Main>
  );
}
