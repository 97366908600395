import React, { useState, useEffect, useContext } from "react";
import TeamList from "../Team/TeamList";
import Team from "../Team/Team";
import styled from "styled-components";
import { Route, Switch, useRouteMatch, useParams } from "react-router-dom";
import { DataContext } from "../../contexts/DataContext";

const Main = styled.div`
  display: flex;
  background-color: #ececec;
`;
const Content = styled.div`
  display: flex;
  box-sizing: border-box;
  padding: 1em;
  width: 100%;
`;
export default function Teams() {
  const [teams, setTeams] = useState();
  const { getTeams } = useContext(DataContext);
  let match = useRouteMatch();

  useEffect(() => {
    getData();
  }, []);

  function getData() {
    getTeams().then((data) => {
      setTeams(data);
    });
  }

  return (
    <Main>
      <>
        <Switch>
          <Route path={`${match.path}/:teamId/:seasonId`}>
            <TeamList teams={teams} onUpdate={getData} />
            <Content>
              <Team teams={teams} onUpdate={getData} />
            </Content>
          </Route>
          <Route path={`${match.path}`}>
            <TeamList teams={teams} onUpdate={getData} />
          </Route>
        </Switch>
      </>
    </Main>
  );
}
